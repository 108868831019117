import React from "react";

import styles from "./scss/index.module.scss";

const generateClassName = ({ errors, iconLeft, styleType }) => {
  const baseClass = styles["field"];
  const errorsClass =
    errors && errors.length ? ` ${styles["field--error"]}` : "";
  const iconLeftClass = iconLeft ? ` ${styles["field--left-icon"]}` : "";
  const styleTypeClass =
    styleType === "grey" ? ` ${styles["field--grey"]}` : "";

  return baseClass.concat(errorsClass, iconLeftClass, styleTypeClass);
};
export const TextField = ({
  label = "",
  iconLeft,
  iconRight,
  errors,
  helpText,
  styleType = "white",
  ...rest
}) => (
  <div className={styles.input}>
    {iconLeft ? <span className={styles["icon-left"]}>{iconLeft}</span> : null}
    {iconRight ? (
      <span className={styles["icon-right"]}>{iconRight}</span>
    ) : null}
    <div className={styles["content"]}>
      <input
        {...rest}
        className={generateClassName({ errors, iconLeft, styleType })}
      />
      {label ? <span className={styles["label"]}>{label}</span> : null}
    </div>
    {errors && (
      <span className={styles["error"]}>
        {errors.map((error) => error.message).join(" ")}
      </span>
    )}
    {helpText && <span className={styles["help-text"]}>{helpText}</span>}
  </div>
);
