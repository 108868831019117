import classNames from "classnames";
import Link from "next/link";
import React from "react";
import { generatePath } from "react-router";
import { FormattedMessage } from "react-intl";
import { commonMessages } from "../../intl";
import { paths } from "../../paths";
//import { commonMessages } from "../../intl";

import styles from "./scss/index.module.scss";
import { useStore } from "../../context/state";

export const extractBreadcrumbs = (category, ancestors) => {
  const constructLink = ({ slug, name }) => ({
    link: generatePath(paths.category, { slug }),
    value: name,
  });

  let breadcrumbs = [constructLink(category)];

  if (ancestors && ancestors.length) {
    const ancestorsList = ancestors.map((category) => constructLink(category));
    breadcrumbs = ancestorsList.concat(breadcrumbs);
  }
  return breadcrumbs;
};

const getBackLink = (breadcrumbs) =>
  breadcrumbs.length > 1
    ? breadcrumbs[breadcrumbs.length - 2].link
    : paths.home;

export const Breadcrumbs = ({ breadcrumbs }) => {
  const { isMobile } = useStore();

  if (false) {
    return (
      <div className={styles.breadcrumbs}>
      <Link href={getBackLink(breadcrumbs)} prefetch={false}>
        <a>Back</a>
      </Link>
    </div>
    );
  }
  return (
    <ul className={styles.breadcrumbs}>
        <li>
          <Link href={paths.home} prefetch={false}>
            <a><FormattedMessage {...commonMessages.homePage}/></a>
          </Link>
        </li>
        {breadcrumbs.map((breadcrumb, index) => (
          <li
            key={`${breadcrumb.value}-${index}`}
            className={classNames({
              [styles["breadcrumbs__active"]]: index === breadcrumbs.length - 1,
            })}
          >
            <Link href={breadcrumb.link} prefetch={false}>
              <a>{breadcrumb.value}</a>
            </Link>
          </li>
        ))}
      </ul>
  );
};
