import React from "react";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { RichTextEditorContent } from "../../components/atoms/RichTextEditorContent";
import { TextField } from "../../components/TextField";
import { FormattedMessage, useIntl } from "react-intl";
import { Button } from "../../components/Button";
import { inputMessages } from "../../intl";
import styles from "./scss/index.module.scss";

export const Page = ({ title, description }) => {
  const intl = useIntl();
  return (
    <div className={styles["login-page"]}>
      <div className="container">
        <Breadcrumbs
          breadcrumbs={[{ link: "/login", value: intl.formatMessage({id: "views.login.page.breadcrumbs", defaultMessage: "Login to your account"}) }]}
        />
      </div>
      <div className="container">
        <h1 className="hero-title">{title || <FormattedMessage id="views.login.page.title" defaultMessage="Login to your personal account"/>}</h1>
        {description && (
          <div className={styles.description}>
            <RichTextEditorContent jsonData={description} />
          </div>
        )}
        <form action="javascript:void(0);" className={styles.form}>
          <TextField label={intl.formatMessage(inputMessages.email)}
                     helpText={intl.formatMessage(inputMessages.emailHelper)} />
                 <TextField label={intl.formatMessage(inputMessages.password)}
                     helpText={intl.formatMessage(inputMessages.passwordHelper)} />
          <Button color="secondary">
              <FormattedMessage id="views.login.page.action" defaultMessage="Login"/>
          </Button>
        </form>
      </div>
    </div>
  );
};
