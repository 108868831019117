import React, { useMemo } from "react";

import { MetaWrapper } from "../../components/Meta";
import { Layout } from "../../components/Layout";
import { prepareMetaPage } from "../../components/utils/meta";
import { prepareSEO } from "../../components/utils/seo";
import { Page } from "./Page";

export const LoginView = ({data}) => {
  const [meta, metaDefault, seo] = useMemo(() => {
    const meta = prepareMetaPage(data?.page);
    const metaDefault = prepareMetaPage(data?.pageDefault);
    return [meta, metaDefault, prepareSEO("page_login", meta, metaDefault)];
  }, []);

  return (
    <MetaWrapper
      meta={seo}
    >
      <Layout meta={meta}>
        <Page
          title={meta.page_login_title || metaDefault.page_login_title}
          description={meta.page_login_description || metaDefault.page_login_description}
        />
      </Layout>
    </MetaWrapper>
  );
};
