
import { incrementalStaticRegenerationRevalidate, storeId } from "../constants";
import { LoginView, loginQuery } from "../views/Login";
import { locale } from "../constants";
import { preprocessMetaPage } from "../components/utils/meta";
import { getSaleorApi } from "../components/utils/ssr";

export default LoginView;

export const getStaticProps = async () => {
  const { apolloClient } = await getSaleorApi();
  const { data } = await apolloClient.query({
    query: loginQuery,
    variables: { pageSlug: storeId + "-main-page"},
  });

  const response2 = await apolloClient.query({
    query: loginQuery,
    variables: { pageSlug: "login-page-" + locale },
  });

  data.page = preprocessMetaPage(data.page, ["page_login"]);

  return {
    revalidate: incrementalStaticRegenerationRevalidate,
    props: {
      data: {
        page: data.page || {},
        pageDefault: response2.data.page || {}
      },
    },
  };
};
